/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

html {
  scroll-behavior: smooth !important;
}
@font-face {
  font-family: "Clash Grotesk Variable";
  src: url("./assets/fonts/ClashGrotesk/OTF/ClashGrotesk-Bold.otf")
    format("opentype");
  font-style: normal;
  font-weight: 600;
}

body {
  font-family: "Clash Grotesk Variable";
}

@font-face {
  font-family: "Clash Grotesk Variable";
  src: url("./assets/fonts/ClashGrotesk/OTF/ClashGrotesk-Semibold.otf")
    format("opentype");
  font-style: normal;
  font-weight: 500;
}

body {
  font-family: "Clash Grotesk Variable";
}

@font-face {
  font-family: "Clash Grotesk Variable";
  src: url("./assets/fonts/ClashGrotesk/OTF/ClashGrotesk-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: 400;
}

body {
  font-family: "Clash Grotesk Variable";
}
@font-face {
  font-family: "Clash Grotesk Variable";
  src: url("./assets/fonts/ClashGrotesk/OTF/ClashGrotesk-Light.otf")
    format("opentype");
  font-style: normal;
  font-weight: 300;
}

body {
  font-family: "Clash Grotesk Variable";
}

@font-face {
  font-family: "Clash Grotesk Variable";
  src: url("./assets/fonts/ClashGrotesk/OTF/ClashGrotesk-Medium.otf")
    format("opentype");
  font-style: normal;
  font-weight: 200;
}
body {
  font-family: "Clash Grotesk Variable";
}
.animated-text {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #91a6ca44;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #e04f16;
  border-radius: 5px;
}
